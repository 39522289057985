.parkinglotttag{
  position: absolute;
  top: -38px;
  // right:60px;
  right:0;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}