.headerbox {
    // position: absolute;
    // top: 0;
    // left: 0;
    // // display: flex;
    // // justify-content: center;
    // // align-items: center;
    // // overflow-y: visible;
    // width: 100%;
    // height: 58px;
    // /*box-shadow: 0 0 8px 0 rgba(45, 86, 150, 1);*/
    // z-index: 2;
    // padding: calc(0.5vw) 0;
    // margin-right: -1.3vw;
    // background-image: url(../../assets/img/header/dingbubiaoti2@.png);
    // background-size: cover;
    .maptitle_box {
        width: calc(100vw - 44vw); //56vw
        max-width: 1725px;
        height: 100%;
        float: left;
        margin-left: -1.5vw;
        margin-top: -0.5vw;
        position: relative;
        padding-top: 0.5vh;
    }
    .maptitle_bg {
        background-image: url(../../assets/img/header/titlebg2@.png);
        background-size: 100%;
        background-repeat: no-repeat;
        width: 105%;
        height: 128px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        margin: 0 -2.5%;
    }
    .maptitle {
        font-size: 28px;
        color: #DBEFFF;
        text-align: center;
        z-index: 999;
        position: relative;
        padding-top: 8px;
    }
    // .btn_diamond {
    //     position: absolute;
    //     /* width: 182px;*/
    //     height: 46px;
    //     line-height: 46px;
    //     text-align: center;
    //     text-decoration: none;
    //     font-size: 13px;
    //     color: #fff;
    //     top: 0.5vh;
    //     right: 19%;
    //     background-image: url(../../assets/img/header/right-btn.png);
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     &.btn_diamond_reverse {
    //         // background-image: url(../../assets/img/header/right-btn.png);
    //     }
    // }
    // &.header_sm {
    //     height: 78px;
    //     .title {
    //         padding-top: 10px;
    //         width: 646px;
    //         height: 90px;
    //         background-image: url(../../assets/img/title_sm.png);
    //     }
    // }
    &.header_wide .title {
        margin: 0 360px;
    }
    .title {
        margin: 0 114px;
        box-sizing: border-box;
        padding-top: 16px;
        width: 652px;
        height: 115px;
        background-image: url(../../assets/img/title.png);
        background-size: cover;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        .sub_title {
            font-size: 13px;
            text-transform: uppercase;
        }
    }
    .city_select {
        width: 70px;
        font-size: 14px;
         :global {
            .ant-select-selector {
                margin-left: -41px;
                padding: 0 41px 4px 41px !important;
                box-sizing: content-box;
            }
            .ant-select-arrow {
                margin-top: -9px;
            }
        }
    }
    .left_content {
        position: absolute;
        left: 30px;
        img {
            width: 48px;
            height: 48px;
        }
        p {
            vertical-align: top;
            display: inline-block;
            margin-left: 23px;
            font-size: 13px;
            line-height: 25px;
        }
    }
    .right_content {
        position: absolute;
        right: 45px;
        button {
            margin-left: 35px;
            line-height: 32px;
            font-size: 16px;
            color: #91B5DA;
            img {
                vertical-align: top;
                display: inline-block;
                margin-left: 5px;
                width: 32px;
                height: 32px;
            }
        }
    }
}

.system_bg,
.btn_diamond {
    background-image: url(../../assets/img/header/left-btn.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 6.4vw;
    max-width: 188px;
    height: 100%;
    max-height: 50px;
    float: left;
    cursor: pointer;
    margin-left: -1vw;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.btn_diamond {
    background-image: url(../../assets/img/header/right-btn.png);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 25px;
    margin-left: -1.6vw;
}

.system_box {
    line-height: 100%;
    color: #24C7F9;
    font-size: 12px;
    text-align: center;
    padding-top: 8px;
}

.times_bg {
    background-image: url(../../assets/img/header/time_bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 17.8vw;
    max-width: 535px;
    height: 100%;
    float: left;
    display: flex;
    justify-content: left;
    align-items: center;
}

.times_box {
    // position: absolute;
    // top: 9px;
    padding-left: calc(1.8vw);
    width: 280px;
    height: 100%;
    // line-height: 100%;
    color: #24C7F9;
    font-size: 12px;
    padding-top: 6px;
}

.search_bg {
    background-image: url(../../assets/img/header/search-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: calc(100vw - 81.4vw);
    max-width: 1140px;
    height: 32px;
    z-index: 9999;
    float: left;
    margin-left: -1.2vw;
    padding-left: 2vw;
}